import React from 'react';
import { Redirect } from 'react-router-dom';

import { useSearchParams, parsers } from '../../shared/hooks/useSearchParams';

function Root() {
  const { returnTo } = useSearchParams({
    returnTo: { type: parsers.string },
  });

  if (returnTo) {
    return <Redirect to={returnTo} />;
  }

  return null;
}

export default Root;
